<template>
    <div class="home">
        <Intro />
        <Spacer :space="5" :mobile-space="0" />
        <About />
        <Spacer :space="5" />
        <Audio />
        <Spacer :space="5" />
        <Paint />
        <Spacer :space="10" :mobile-space="5" />
        <Authors />
    </div>
</template>
<script>
import Intro from '@/components/Intro'
import About from '@/components/About'
import Audio from '@/components/Audio'
import Paint from '@/components/Paint'
import Authors from '@/components/Authors'
import Spacer from '@/components/Spacer'
export default {
    name: 'Home',
    components: {
        Intro,
        About,
        Audio,
        Paint,
        Authors,
        Spacer,
    },
    props: {},
    mixins: [],
    data() {
        return {}
    },
    computed: {},
    mounted() {},
    methods: {},
    watch: {},
}
</script>
<style lang="scss" scoped>
@import 'src/scss/common/index.scss';
.home {
    position: relative;
    overflow: hidden;
}
.thumbnails {
    display: flex;
    flex-wrap: wrap;
}
</style>
