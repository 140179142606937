<template>
    <div class="spacer" :style="spacerStyle"></div>
</template>
<script>
import responsiveCondition from '@/mixins/responsiveCondition'
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
export default {
    name: "Spacer",
    components: {
    },
    props: {
        space: {
            type: Number,
            default: 0
        },
        mobileSpace: Number,
        desktopSpace: Number,
    },
    mixins: [responsiveCondition],
    data () {
        return {
        }
    },
    computed: {
        s () {return this.responsiveCondition(this.desktopSpace, this.mobileSpace, this.space)},
        spacerStyle () {
            return {
                height: `${this.s}rem`
            }
        }
    },
    mounted () {
    },
    methods: {
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
</style>
