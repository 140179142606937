<template>
    <div class="fluid-svg">
        <div class="fluid-svg__parent" :style="svgStyle" v-html="svg"></div>
    </div>
</template>
<script>
export default {
    name: "FluidSvg",
    components: {
    },
    props: {
        svg: String
    },
    mixins: [],
    data () {
        return {
        }
    },
    computed: {
        dimensions () {
            const result = /viewBox="([\d ]*)"/g.exec(this.svg)
            const d = (result[1] || '0 0 0 0').split(' ').slice(2, 4)
            return {
                w: d[0],
                h: d[1],
            }
        },
        ratio () {
            const {w,h} = this.dimensions
            return h/w*100
        },
        svgStyle () {
            return {
                paddingTop: `${this.ratio}%`
            }
        }
    },
    mounted () {
    },
    methods: {
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
.fluid-svg__parent{
	position: relative;
	/deep/ svg{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		fill: inherit;
	}
}
</style>
