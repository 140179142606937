<template>
    <div class="intro">
        <Blob :width="47" :top="-7" :right="0" :number="0"/>
        <Spacer :mobile-space="5" />
        <layer :layer="100">
            <padding :pad="1" :desktop-pad="3">
                <split reverse expand equal>
                    <template slot="start">
                        <layout align="center">
                            <padding :desktop-pad="3">
                                <img src="@/assets/images/tryk_og_glad_forside.jpg" alt="">
                            </padding>
                            <Spacer :mobile-space="2" />
                        </layout>
                    </template>
                    <template slot="end">
                        <max :width="27">
                            <heading type="chapter" notsemantic>Et helt nyt koncept!</heading>
                            <heading type="main">Tryk & glad godnatlæsning er et enkelt redskab til at skabe ro og gode betingelser for en hyggelig læsestund mellem barn og voksen.</heading>
                            <Spacer :space="2"></Spacer>
                            <split >
                                <template slot="start">
                                    <padding :right="1">
                                        <BuyNow />
                                    </padding>
                                    <Spacer :mobile-space="1"/>
                                </template>
                                <template slot="end">
                                    <Preview />
                                </template>
                            </split>
                        </max>
                    </template>
                </split>
            </padding>
        </layer>

    </div>
</template>
<script>
import Heading from '@/components/Heading'
import Split from '@/components/Split'
import BuyNow from '@/components/BuyNow'
import Preview from '@/components/Preview'
import Spacer from '@/components/Spacer'
import Padding from '@/components/Padding'
import Max from '@/components/Max'
import Layout from '@/components/Layout'
import Blob from '@/components/Blob'
import Layer from '@/components/Layer'
export default {
    name: "Intro",
    components: {
        Heading,
        Split,
        BuyNow,
        Preview,
        Spacer,
        Padding,
        Max,
        Layout,
        Blob,
        Layer,
    },
    props: {
    },
    mixins: [],
    data () {
        return {
        }
    },
    computed: {
    },
    mounted () {
    },
    methods: {
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
</style>
