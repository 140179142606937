export default {
	namespaced: true,
	state: {
		loaderVisible: true,
	},
	mutations: {
		setLoader (state, value) {
			state.loaderVisible = value
		},
	},
	actions: {
		hideLoader ({commit}) {
            commit('setLoader', false)
        },
		showLoader ({commit}) {
            commit('setLoader', true)
        },
	}
}
