<template>
    <div class="blob" :style="blobStyle">
        <fluid-svg :svg="blob"></fluid-svg>
    </div>
</template>
<script>
import FluidSvg from '@/components/FluidSvg'
import Max from '@/components/Max'
export default {
    name: "Blob",
    components: {
        FluidSvg,
        Max,
    },
    props: {
        number: Number,
        width: Number,
        top: Number,
        bottom: Number,
        left: Number,
        right: Number,
        scale: Number,
    },
    mixins: [],
    data () {
        return {
        }
    },
    computed: {
        blob () {
            return this.blobs[this.number === undefined ? 0 : this.number]
        },
        blobs () {
            return new Array(6).fill().map((item, idx) => {
                return require(`!svg-inline-loader?classPrefix!@/assets/svgs/blob${idx+1}.svg`)
            })
        },
        blobStyle () {
            const width = `${this.width}%`
            const top = this.top && `${this.top}rem`
            const bottom = this.bottom && `${this.bottom}rem`
            const left = this.left && `${this.left}rem`
            const right = this.right && `${this.right}rem`
            const transform = this.scale && `scale(${this.scale})`
            return {width,top,bottom,left,right,transform}
        }
    },
    mounted () {
    },
    methods: {
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
.blob{
    position: absolute;
    z-index: 0;
    @include down {
        display: none;
    }
}
</style>
