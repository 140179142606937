<template>
    <footer class="footer">
        <div class="footer__main">
            <div class="footer__item">
                <padding :pad="3">
                    <img src="~@/assets/images/bamsebunke.png" alt="" />
                </padding>
            </div>
            <div class="footer__item">
                <padding :pad="2" :mobile-hor="1">
                    <heading type="small" notsemantic>Køb Tryk & glad godnatlæsning</heading>
                    <Spacer :space="1" />
                    <passage small>
                        Tryk & glad godnatlæsning er et enkelt redskab til at skabe ro og gode betingelser for en hyggelig læsestund mellem barn og voksen.
                    </passage>
                    <Spacer :space="1.25" />
                    <BuyNow />
                </padding>
            </div>
            <div class="footer__item">
                <padding :pad="2" :mobile-hor="1">
                    <heading type="small" notsemantic>Forlaget bag</heading>
                    <Spacer :space="1" />
                    <passage small>ROSINANTE & CO</passage>
                    <passage small>Købmagergade 62, 3.</passage>
                    <passage small>1150 København K</passage>
                    <Spacer :space="1" />
                    <passage small>Tlf. 3341 1800</passage>
                    <a href="http://rosinante-co.dk/forlag/host-og-son" target="_blank"><btn secondary>Høst og Søn</btn></a>
                    <Spacer :space="1" />
                    <passage small>Redaktør: Nanna Nøhr</passage>
                </padding>
                <Spacer :space="5" />
            </div>
        </div>
    </footer>
</template>
<script>
import Heading from '@/components/Heading'
import Passage from '@/components/Passage'
import Split from '@/components/Split'
import Padding from '@/components/Padding'
import Max from '@/components/Max'
import Spacer from '@/components/Spacer'
import BuyNow from '@/components/BuyNow'
import Btn from '@/components/Btn'
export default {
    name: 'AppFooter',
    components: {
        Heading,
        Passage,
        Split,
        Padding,
        Max,
        Spacer,
        BuyNow,
        Btn,
    },
    props: {},
    mixins: [],
    data() {
        return {}
    },
    computed: {},
    mounted() {},
    methods: {},
    watch: {},
}
</script>
<style lang="scss" scoped>
@import 'src/scss/common/index.scss';
.footer {
    @include up {
        display: flex;
    }
}
.footer__main {
    @include up {
        display: flex;
        width: 100%;
    }
}
.footer__item {
    flex: 0 0 33%;
}
</style>
