<template>
    <div class="author">
        <split :reverse="reverse" equal>
            <template slot="start">
                <layer>
                    <Blob v-if="reverse" :width="100" :top="1" :right="-5" :scale="1.3" :number="blob"/>
                    <Blob v-else :width="100" :top="1" :left="-5" :scale="1.3" :number="blob"/>

                    <layer :layer="100">
                        <padding :desktop-left="reverse?5:0" :desktop-right="reverse?0:5">
                            <img :src="image" alt="">
                        </padding>
                    </layer>
                </layer>
                <Spacer :mobile-space="1" />
            </template>
            <template slot="end">
                <heading type="title">{{name}}</heading>
                <Spacer :space="1" />
                <passage>{{text}}</passage>
                <Spacer :space="2" />
                <passage>
                    {{social}}
                    <template v-for="ins of insta">
                        <padding :right="0.5" inline>
                            <a :href="instaLink(ins)" target="_blank">
                                <btn secondary >{{ins}}</btn>
                            </a>
                        </padding>
                    </template>
                </passage>
                <Spacer :space="2" />
                <a :href="link" target="_blank">
                    <btn>{{web}}</btn>
                </a>
            </template>
        </split>
    </div>
</template>
<script>
import Heading from '@/components/Heading'
import Split from '@/components/Split'
import Passage from '@/components/Passage'
import Padding from '@/components/Padding'
import Spacer from '@/components/Spacer'
import Btn from '@/components/Btn'
import Layer from '@/components/Layer'
import Blob from '@/components/Blob'
export default {
    name: "Author",
    components: {
        Heading,
        Split,
        Passage,
        Padding,
        Spacer,
        Btn,
        Layer,
        Blob,
    },
    props: {
        name: String,
        text: String,
        social: String,
        web: String,
        image: String,
        reverse: Boolean,
        link: String,
        insta: Array,
        blob: Number,
    },
    mixins: [],
    data () {
        return {
        }
    },
    computed: {
    },
    mounted () {
    },
    methods: {
        instaLink (val) {
            return `https://www.instagram.com/${val.replace('@','')}/`
        }
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
</style>
