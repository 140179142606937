<template>
    <a href="https://www.saxo.com/dk/tryk-og-glad-godnatlaesning_katrine-birklena-adalberthanne-marie-bersaa_indbundet_9788763859820" class="buy-now" target="_blank">
        <btn>Køb bogen her</btn>
    </a>
</template>
<script>
import Btn from '@/components/Btn'
export default {
    name: "BuyNow",
    components: {
        Btn,
    },
    props: {
    },
    mixins: [],
    data () {
        return {
        }
    },
    computed: {
    },
    mounted () {
    },
    methods: {
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
</style>
