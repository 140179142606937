<template>
    <div class="authors">
        <layer :layer="100">
            <div class="authors__head">
                <padding :mobile-hor="1">
                    <layout align="center">
                        <heading type="chapter">Forfatterne bag bogen</heading>
                        <Spacer :space="3" :mobile-space="1.5" />
                        <max :width="60">
                            <img src="@/assets/images/gruppe.jpg" alt="" />
                        </max>
                        <Spacer :space="5" :desktop-space="10" />
                    </layout>
                </padding>
            </div>
            <div class="authors__authors">
                <padding :mobile-hor="1">
                    <max :width="50" center>
                        <template v-for="(author, idx) in authors">
                            <Author :reverse="idx % 2 === 0" :key="author.name" :name="author.name" :text="author.text" :image="author.image" :social="author.social" :web="author.web" :insta="author.insta" :link="author.link" :blob="author.blob" />
                            <Spacer :space="7" />
                        </template>
                    </max>
                </padding>
            </div>
        </layer>
    </div>
</template>
<script>
import Heading from '@/components/Heading'
import Layout from '@/components/Layout'
import Spacer from '@/components/Spacer'
import Max from '@/components/Max'
import Author from '@/components/Author'
import Padding from '@/components/Padding'
import Layer from '@/components/Layer'
export default {
    name: 'Authors',
    components: {
        Heading,
        Layout,
        Spacer,
        Max,
        Padding,
        Author,
        Layer,
    },
    props: {},
    mixins: [],
    data() {
        return {
            authors: [
                {
                    name: 'Katrine Birk',
                    image: require('@/assets/images/katrine.jpg'),
                    text: `Katrine Birk har studeret zoneterapi og akupressur i over 10 år og har en stor passion for at behandle babyer og børn. Hun underviser forældre i, hvordan de selv kan behandle deres børn, og så uddanner hun alternative behandlere. Katrine står bag de populære TRYK & GLAD-bøger, som har hjulpet en masse babyer, og ikke mindst deres forældre, med at få mere velvære ind i hverdagen. Katrine er selv mor til to frække piger fra 2013 og 2016.`,
                    social: 'Følg Katrine på Instagram:',
                    insta: ['@katrine_birk', '@tryk_og_glad'],
                    web: 'Læs mere på katrinebirk.dk',
                    link: 'https://www.katrinebirk.dk/',
                    blob: 3,
                },
                {
                    name: 'Anne Marie Berså',
                    image: require('@/assets/images/anne_marie.jpg'),
                    text: `Anne Marie Berså er tekstforfatter, rådgiver og iværksætter. Hun har specialiseret sig i at bruge strategisk og kreativ kommunikation, forretningsudvikling og personlig branding til at øge mennesker og virksomheders værdi. Hun debuterer som børnebogsforfatter med de små godnathistorier om Lille Tumle og hendes tøjdyr. Anne Marie er mor til en pige fra 2017.`,
                    social: 'Følg Anne Marie på Instagram:',
                    insta: ['@annemariebilleder'],
                    web: 'Læs mere på createunlimited.dk',
                    link: 'http://createunlimited.dk',
                    blob: 4,
                },
                {
                    name: 'Lena Adalberth',
                    image: require('@/assets/images/lena.jpg'),
                    text: `Lena Adalberth er uddannet cand.mag. i Sprogpsykologi fra Københavns Universitet. Hun er skaber af brandet MOAR, hvor hun holder foredrag og rådgiver forældre om børns sproglige udvikling. Lena står bl.a. bag bogens afsnit om at læse for og med sine børn. Hun har selv to piger fra henholdsvis 2016 og 2018.`,
                    social: 'Følg Lena på Instagram:',
                    insta: ['@lenaadalberth'],
                    web: 'Læs mere på moar.dk',
                    link: 'https://moar.dk',
                    blob: 5,
                },
            ],
        }
    },
    computed: {},
    mounted() {},
    methods: {},
    watch: {},
}
</script>
<style lang="scss" scoped>
@import 'src/scss/common/index.scss';
.authors__head {
    text-align: center;
}
</style>
