<template>
    <div class="logo">
        TRYK &<br> GLAD TUMLE
    </div>
</template>
<script>
export default {
    name: "Logo",
    components: {
    },
    props: {
    },
    mixins: [],
    data () {
        return {
        }
    },
    computed: {
    },
    mounted () {
    },
    methods: {
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
.logo{
    font-size: 0.8rem;
    color: $color-petrol;
    font-weight: 700;
    line-height: 1;
}
</style>
