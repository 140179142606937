<script>
export default {
	name: "Heading",
	render: function(createElement) {
		const {level, notsemantic, classes} = this.current
        if(this.notsemantic || notsemantic) {
            return createElement(
    			'div', {
                    class: ['h' + level, this.type, classes]
                },
    			this.$slots.default
    		)
        }
		return createElement(
			'h' + level, {
				class: [this.type, classes]
			},
			this.$slots.default
		)
	},
	components: {},
	props: {
        type: {
            type: String,
            default: 'title',
        },
		notsemantic: Boolean,
    },
	mixins: [],
	data() {
		return {
            list: {
				'main': {
					level: 1,
				},
				'chapter': {
					level: 2,
				},
				'title': {
					level: 3,
				},
				'subhead': {
					level: 4,
				},
				'small': {
					level: 4,
				},
            }
        }
	},
	computed: {
        current () {
            return this.list[this.type] || this.list['title']
        }
    },
	mounted() {},
	methods: {},
	watch: {}
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
h1,h2,h3,h4,h5,h6{
	font-family: $font-primary;
	font-weight: 700;
	line-height: 1;
	font-size: 1rem;
	margin: 0;
}
.main{
	font-size: 1.5rem;
	line-height: 1.35;
	font-weight: 700;
	font-family: $font-primary;
	margin: 0;
}
.title{
	font-size: 2rem;
	line-height: 1.25;
	font-weight: 700;
	font-family: $font-primary;
}
.chapter, .subhead{
	font-size: 1rem;
	font-family: $font-secondary;
	font-weight: normal;
	line-height: 1.6;
	color: $color-red;
	margin: 0;
}
.small{
	font-size: 1.25rem;
	line-height: 1.25;
	font-weight: 700;
	font-family: $font-primary;
}
/*
* Custom
* --------------------------------------------------
*/

</style>
