import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
export default {
    computed: {
        ...mapGetters('breakpoints', ['mobile', 'desktop']),
    },
    methods: {
        notUndefiend (value) {
            return value != undefined
        },
        mobileNotUndefined (value) {
            return this.mobile && this.notUndefiend(value)
        },
        desktopNotUndefined (value) {
            return this.desktop && this.notUndefiend(value)
        },
        responsiveCondition (desktop, mobile, defaultValue) {
            return this.desktopNotUndefined(desktop) ? desktop : this.mobileNotUndefined(mobile) ? mobile : defaultValue
        },
        firstSetValue (...args) {
            return args.filter(item => this.notUndefiend(item))[0]
        }
    },
}
