<template>
    <a href="https://issuu.com/rosinanteco/docs/tryk_og_glad_laeseproeve" class="preview" target="_blank">
        <btn secondary>Læs en smagsprøve</btn>
    </a>
</template>
<script>
import Btn from '@/components/Btn'
export default {
    name: "Preview",
    components: {
        Btn,
    },
    props: {
    },
    mixins: [],
    data () {
        return {
        }
    },
    computed: {
    },
    mounted () {
    },
    methods: {
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
</style>
