import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import breakpoints from '@/store/modules/breakpoints'
import loader from '@/store/modules/loader'

Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    breakpoints,
    loader,
  },
  // plugins: process.env.NODE_ENV !== 'production'
  //   ? [createLogger()]
  //   : []
})

export default store
