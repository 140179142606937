<template>
    <div class="split">
        <div class="split__row" :class="splitRowClass">
            <div class="split__col">
                <slot name="start"></slot>
            </div>
            <div class="split__col">
                <slot name="end"></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: "Split",
    components: {
    },
    props: {
        reverse: Boolean,
        expand: Boolean,
        force: Boolean,
        inline: Boolean,
        equal: Boolean,
    },
    mixins: [],
    data () {
        return {
        }
    },
    computed: {
        splitRowClass () {
            return {
                'split__row--reverse': this.reverse,
                'split__row--expand': this.expand,
                'split__row--force': this.force,
                'split__row--inline': this.inline,
                'split__row--equal': this.equal,
            }
        }
    },
    mounted () {
    },
    methods: {
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
.split{
    @include up {
        display: flex;
    }
}
.split__row{
    @include up {
        display: flex;
        width: 100%;
        align-items: center;
    }
}
.split__row--reverse{
    @include up {
        flex-direction: row-reverse;
    }
}
.split__row--inline{
    @include up {
        display: inline-flex;
    }
}
.split__row--expand{
    @include up {
        width: 100%;
    }
}
.split__row--equal .split__col{
    flex: 0 1 50%;
}

/*
* Force
* --------------------------------------------------
*/
.split__row--force{
    @include down {
        display: flex;
        align-items: center;
    }
}
.split__row--force.split__row--expand{
    @include down {
        width: 100%;
    }
}
.split__row--force.split__row--inline{
    @include down {
        display: inline-flex;
    }
}
</style>
