<template>
    <div class="audio">
        <layer :layer="100">
            <padding :pad="1" :desktop-hor="3">
                <max :width="30">
                    <heading type="chapter">Lyt og tryk</heading>
                    <heading type="main" notsemantic>Her kan du hente alle historierne om Tumles tøjdyr, så du kan lytte, imens du masserer dit barn. Historierne er oplæst af Katrine Birk. God fornøjelse.</heading>
                </max>
                <Spacer :space="2" />
                <split>
                    <template slot="start">
                        <padding :right="1" :mobile-bottom="1">
                            <a href="/content/tryk_og_glad_godnatlaesning.mp3" download="tryk_og_glad_godnatlaesning.mp3" target="_blank">
                                <btn>Download hele lydbogen</btn>
                            </a>
                        </padding>
                    </template>
                    <template slot="end">
                        <passage>Eller enkelvis nedenunder</passage>
                    </template>
                </split>
                <Spacer :mobile-space="1" :desktop-space="1" />
            </padding>
            <padding :desktop-hor="0">
                <div class="audio__files" ref="flickity">
                    <div class="audio__file" v-for="(audio, idx) in audio" :key="audio.file">
                        <padding :desktop-hor="0.5">
                            <!-- <template v-if="desktop">
                                <a target="_blank" :href="audio.file" :download="audio.filename">
                                    <div class="audio__file__image">
                                        <img :src="audio.image" alt="" class="audio__file__image__img" />
                                    </div>
                                </a>
                            </template> -->
                            <div class="audio__file__image">
                                <img :src="audio.image" alt="" class="audio__file__image__img" />
                            </div>
                            <heading type="subhead" notsemantic>{{ audio.name }}</heading>
                            <a target="_blank" :href="audio.file" :download="audio.filename">
                                <btn secondary>Download lydhistorie</btn>
                            </a>
                        </padding>
                    </div>
                </div>
            </padding>
        </layer>
    </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
require('flickity/css/flickity.css')
import Flickity from 'flickity'
require('flickity-imagesloaded')
import Heading from '@/components/Heading'
import Padding from '@/components/Padding'
import Max from '@/components/Max'
import Spacer from '@/components/Spacer'
import Btn from '@/components/Btn'
import Split from '@/components/Split'
import Passage from '@/components/Passage'
import Layer from '@/components/Layer'
export default {
    name: 'Paint',
    components: {
        Heading,
        Padding,
        Max,
        Spacer,
        Btn,
        Split,
        Passage,
        Layer,
    },
    props: {},
    mixins: [],
    data() {
        return {
            audio: [
                { name: 'Giraffen Albert', filename: 'giraffen_albert', file: '/content/giraffen_albert.mp3', image: require('@/assets/images/audio_thumbnail_albert.png') },
                { name: 'Pandaen Nova', filename: 'pandaen_nova', file: '/content/pandaen_nova.mp3', image: require('@/assets/images/audio_thumbnail_nova.png') },
                { name: 'Kænguruen Emma', filename: 'kaenguruen_emma', file: '/content/kaenguruen_emma.mp3', image: require('@/assets/images/audio_thumbnail_emma.png') },
                { name: 'Bjørnen Magne', filename: 'bjoernen_magne', file: '/content/bjoernen_magne.mp3', image: require('@/assets/images/audio_thumbnail_magne.png') },
                { name: 'Aben Theodor', filename: 'aben_theodor', file: '/content/aben_theodor.mp3', image: require('@/assets/images/audio_thumbnail_theodor.png') },
                { name: 'Flodhesten Ida', filename: 'flodhesten_ida', file: '/content/flodhesten_ida.mp3', image: require('@/assets/images/audio_thumbnail_ida.png') },
                { name: 'Søløven Astrid', filename: 'soeloeven_astrid', file: '/content/soeloeven_astrid.mp3', image: require('@/assets/images/audio_thumbnail_astrid.png') },
            ],
        }
    },
    computed: {
        ...mapGetters('breakpoints', ['mobile', 'desktop']),
    },
    mounted() {
        this.$nextTick(this.setupFlickity)
    },
    methods: {
        setupFlickity() {
            const { flickity } = this.$refs
            this.flkty = new Flickity(flickity, {
                imagesLoaded: true,
                prevNextButtons: true,
                pageDots: false,
                cellAlign: this.desktop ? 'left' : 'center',
                freeScroll: this.desktop,
                contain: this.desktop,
            })
        },
    },
    watch: {},
}
</script>
<style lang="scss" scoped>
@import 'src/scss/common/index.scss';

// .audio__files {
//     @include up {
//         display: flex;
//     }
// }
.audio /deep/ .flickity-button {
    background-color: $color-petrol;
}
.audio /deep/ .flickity-button-icon {
    fill: $color-white;
}
.audio__file {
    text-align: center;
    @include down {
        width: 75vw;
        padding: 0rem 0.5rem;
    }
    @include up {
        width: 22.5vw;
        // &:first-child {
        //     width: calc(25vw + 2.5rem);
        //     padding-left: 2.5rem;
        // }
        // &:last-child {
        //     width: calc(25vw + 2.5rem);
        //     padding-right: 2.5rem;
        // }
    }
}
.audio__file__image {
    width: 115%;
    left: -7.5%;
    position: relative;
    overflow: hidden;
}
.audio__file__image__img {
    width: 100%;
    height: auto;
}
</style>
