<template>
    <div class="paint">
        <layer :layer="100">
            <padding :pad="1" :desktop-hor="3">
                <max :width="30">
                    <heading type="chapter">Mal selv</heading>
                    <heading type="main" notsemantic>Er dit barn blevet vild med Tumle-universet? Så kan I her downloade maleark og sætte jeres eget præg på historierne.</heading>
                </max>
                <Spacer :space="2" />
                <split>
                    <template slot="start">
                        <padding :right="1" :mobile-bottom="1">
                            <a href="/content/tryk_og_glad_malesider.pdf" download="tryk_og_glad_malesider.pdf" target="_blank">
                                <btn>Download allesammen</btn>
                            </a>
                        </padding>
                    </template>
                    <template slot="end">
                        <passage>Eller enkelvis nedenunder</passage>
                    </template>
                </split>
                <Spacer :mobile-space="2" :desktop-space="3" />
            </padding>
            <padding :desktop-hor="2.5">
                <div class="paint__drawings" ref="flickity">
                    <div class="paint__drawing" v-for="(drawing, idx) in drawings">
                        <padding :desktop-hor="0.5" :mobile-bottom="3">
                            <template v-if="desktop">
                                <a target="_blank" :href="drawing.file" :download="drawing.filename">
                                    <div class="paint__drawing__image">
                                        <padding :pad="1">
                                            <img :src="drawing.image" alt="" />
                                        </padding>
                                    </div>
                                </a>
                            </template>
                            <template v-else>
                                <div class="paint__drawing__image">
                                    <padding :pad="1">
                                        <img :src="drawing.image" alt="" />
                                    </padding>
                                </div>
                            </template>
                            <Spacer :space="1" />
                            <heading type="subhead" notsemantic>{{ drawing.name }}</heading>
                            <a target="_blank" :href="drawing.file" :download="drawing.filename">
                                <btn secondary>Download tegning</btn>
                            </a>
                        </padding>
                    </div>
                </div>
            </padding>
        </layer>
    </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
require('flickity/css/flickity.css')
import Flickity from 'flickity'
require('flickity-imagesloaded')
import Heading from '@/components/Heading'
import Padding from '@/components/Padding'
import Max from '@/components/Max'
import Spacer from '@/components/Spacer'
import Btn from '@/components/Btn'
import Split from '@/components/Split'
import Passage from '@/components/Passage'
import Layer from '@/components/Layer'
export default {
    name: 'Paint',
    components: {
        Heading,
        Padding,
        Max,
        Spacer,
        Btn,
        Split,
        Passage,
        Layer,
    },
    props: {},
    mixins: [],
    data() {
        return {
            drawings: [
                { name: 'Bjørnen Magne', image: require('@/assets/images/tryk_og_glad_malesider_1.jpg'), file: '/content/tryk_og_glad_malesider_1.pdf', filename: 'tryk_og_glad_malesider_1.pdf' },
                { name: 'Tumle og mor', image: require('@/assets/images/tryk_og_glad_malesider_2.jpg'), file: '/content/tryk_og_glad_malesider_2.pdf', filename: 'tryk_og_glad_malesider_2.pdf' },
                { name: 'Kænguruen Emma', image: require('@/assets/images/tryk_og_glad_malesider_3.jpg'), file: '/content/tryk_og_glad_malesider_3.pdf', filename: 'tryk_og_glad_malesider_3.pdf' },
                { name: 'Sovedyrene', image: require('@/assets/images/tryk_og_glad_malesider_4.jpg'), file: '/content/tryk_og_glad_malesider_4.pdf', filename: 'tryk_og_glad_malesider_4.pdf' },
            ],
        }
    },
    computed: {
        ...mapGetters('breakpoints', ['mobile', 'desktop']),
    },
    mounted() {
        if (this.mobile) this.$nextTick(this.setupFlickity)
    },
    methods: {
        setupFlickity() {
            const { flickity } = this.$refs
            this.flkty = new Flickity(flickity, {
                imagesLoaded: true,
                prevNextButtons: true,
                pageDots: false,
                // wrapAround: true,
                // autoPlay: true,
                // pauseAutoPlayOnHover: false,
            })
        },
    },
    watch: {},
}
</script>
<style lang="scss" scoped>
@import 'src/scss/common/index.scss';
.paint {
    &:before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        padding-top: 120%;
        background-image: url('~@/assets/svgs/divider.svg');
        background-size: cover;
        z-index: 0;
        transform: translateY(-10%);
        @include up {
            transform: translateY(-5%);
        }
    }
}
.paint /deep/ .flickity-button {
    background-color: $color-petrol;
}
.paint /deep/ .flickity-button-icon {
    fill: $color-white;
}
.paint__drawings {
    @include up {
        display: flex;
    }
}
.paint__drawing {
    @include down {
        width: 75vw;
        padding: 0rem 0.5rem;
    }
    @include up {
        flex: 0 0 25;
    }
}
.paint__drawing__image {
    background-color: $color-white;
}
</style>
