<template>
    <div class="about">
        <layer>
            <Blob :width="26" :top="-8" :left="0" :number="1" />
            <layer :layer="100">
                <split equal>
                    <template slot="start">
                        <padding :pad="3" :mobile-bottom="1">
                            <img src="@/assets/images/ida_far.png" alt="">
                        </padding>
                    </template>
                    <template slot="end">
                        <padding :hor="1" :desktop-hor="3">
                            <max :width="25">
                                <heading type="chapter">Om bogen</heading>
                                <heading type="title">Tryk og glad godnatlæsning</heading>
                                <Spacer :space="2" />
                                <passage>
                                    Det kan være svært at få en lille energifyldt tumling til at sove! Med Tryk og glad godnatlæsning tilbydes et enkelt redskab til at skabe ro og gode betingelser for en hyggestund mellem barn og voksen – samtidig med at kroppen bliver klar til en god nats søvn.
                                </passage>
                                <Spacer :space="2" />
                                <heading type="subhead">Kom ned i tempo</heading>
                                <passage>
                                    Med syv små godnathistorier om Lille Tumle, der alle lægger op til blid akupressur og zoneterapi under højtlæsningen, er bogen et bud på et kærligt putteritual – og et konkret værktøj til de situationer, hvor både forældre og barn har brug for at komme ned i tempo.
                                </passage>
                            </max>
                        </padding>
                    </template>
                </split>
                <Spacer :desktop-space="5" />
            </layer>
        </layer>
        <layer>
            <Blob :width="23" :top="-10" :right="0" :number="2" />
            <layer :layer="100">
                <split reverse equal>
                    <template slot="start">
                        <padding :pad="3" >
                            <img src="@/assets/images/emma_far.png" alt="">
                        </padding>
                    </template>
                    <template slot="end">
                        <padding :hor="1" :desktop-hor="3">
                            <max :width="25">
                                <heading type="subhead">Hjælp søvnen på vej</heading>
                                <passage>Første del af bogen giver forældre et indblik i syv forskellige akupressur- og zoneterapipunkter på barnets krop, som skaber balance og hjælper søvnen på vej. Desuden tilbydes et indblik i højtlæsningens mange gode egenskaber.</passage>
                                <Spacer :space="2" />
                                <heading type="subhead">Nus og nærvær</heading>
                                <passage>Anden del er en rigt illustreret billedbog til de mindste, hvor syv små historier om Lille Tumle og hendes tøjdyr alle lægger op til rolig dialog og hygge. Hver godnatfortælling inkorporerer et punkt fra bogens første del, som hjælper til en skøn aftenstund med nus og nærvær.</passage>
                            </max>
                        </padding>
                    </template>
                </split>
            </layer>
        </layer>
    </div>
</template>
<script>
import Split from '@/components/Split'
import Heading from '@/components/Heading'
import Passage from '@/components/Passage'
import Padding from '@/components/Padding'
import Max from '@/components/Max'
import Spacer from '@/components/Spacer'
import Blob from '@/components/Blob'
import Layer from '@/components/Layer'
export default {
    name: "About",
    components: {
        Split,
        Heading,
        Passage,
        Padding,
        Max,
        Spacer,
        Blob,
        Layer,
    },
    props: {
    },
    mixins: [],
    data () {
        return {
        }
    },
    computed: {
    },
    mounted () {
    },
    methods: {
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
</style>
