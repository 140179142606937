<template>
    <div class="padding" :style="paddingStyle"><slot></slot></div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import responsiveCondition from '@/mixins/responsiveCondition'
export default {
    name: "Padding",
    components: {
    },
    props: {
        pad: {type: Number, default: 0},
        hor: Number,
        ver: Number,
        top: Number,
        right: Number,
        bottom: Number,
        left: Number,
        mobilePad: Number,
        mobileHor: Number,
        mobileVer: Number,
        mobileTop: Number,
        mobileRight: Number,
        mobileBottom: Number,
        mobileLeft: Number,
        desktopPad: Number,
        desktopHor: Number,
        desktopVer: Number,
        desktopTop: Number,
        desktopRight: Number,
        desktopBottom: Number,
        desktopLeft: Number,
        inline: Boolean,
    },
    mixins: [responsiveCondition],
    data () {
        return {
        }
    },
    computed: {
        p () {return this.responsiveCondition(this.desktopPad, this.mobilePad, this.pad)},
        v () {return this.responsiveCondition(this.desktopVer, this.mobileVer, this.ver)},
        h () {return this.responsiveCondition(this.desktopHor, this.mobileHor, this.hor)},
        t () {return this.responsiveCondition(this.desktopTop, this.mobileTop, this.top)},
        r () {return this.responsiveCondition(this.desktopRight, this.mobileRight, this.right)},
        b () {return this.responsiveCondition(this.desktopBottom, this.mobileBottom, this.bottom)},
        l () {return this.responsiveCondition(this.desktopLeft, this.mobileLeft, this.left)},
        paddingStyle () {
            const t = this.firstSetValue(this.t, this.v, this.p)
            const r = this.firstSetValue(this.r, this.h, this.p)
            const b = this.firstSetValue(this.b, this.v, this.p)
            const l = this.firstSetValue(this.l, this.h, this.p)
            let padding = `${t}rem ${r}rem ${b}rem ${l}rem`
            const display = this.inline && 'inline-block'
            return {padding, display} //Object.assign({}, , display)
        }
    },
    mounted () {
    },
    methods: {
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
</style>
