<template>
    <header class="header">
        <layer :layer="100">
            <padding :pad="1" :desktop-top="2" :desktop-hor="3" :mobile-hor="1" :mobile-top="1.5">
                <split expand force equal>
                    <template slot="start">
                        <Logo />
                    </template>
                    <template slot="end">
                        <layout align="end" class="hide--desktop">
                            <BuyNow />
                        </layout>
                    </template>
                </split>
            </padding>
        </layer>
    </header>
</template>
<script>
import Heading from '@/components/Heading'
import Btn from '@/components/Btn'
import Split from '@/components/Split'
import BuyNow from '@/components/BuyNow'
import Logo from '@/components/Logo'
import Padding from '@/components/Padding'
import Layout from '@/components/Layout'
import Layer from '@/components/Layer'
export default {
    name: "AppHeader",
    components: {
        Heading,
        Btn,
        Split,
        BuyNow,
        Logo,
        Padding,
        Layout,
        Layer,
    },
    props: {
    },
    mixins: [],
    data () {
        return {
        }
    },
    computed: {
    },
    mounted () {
    },
    methods: {
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
.header{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
</style>
