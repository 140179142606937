<template>
    <div id="app">
        <AppHeader />
        <main>
            <router-view :key="$route.fullPath" ref="router"/>
        </main>
        <AppFooter />
    </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
import AppFooter from '@/components/AppFooter'
import AppHeader from '@/components/AppHeader'

const headTitle = 'Tryk & glad Tumle'
const title = 'Et bud på et kærligt putteritual'
const description = 'Er putteritualet med din tumling en udfordring? Her er bogen, der hjælper søvnen kærligt på vej. Når børn skal hjælpes til søvn, kan zoneterapi og gode godnathistorier skabe hyggelige rammer for et kærligt putteritual. Netop dét kombinerer Tryk og glad godnatlæsning.'
const url = 'https://trykoggladtumle.dk'
const shareImage = `${url}/trykoggladtumle_share.jpg`
export default {
    name: "App",
    components: {
        AppFooter,
        AppHeader,
    },
    props: {
    },
    mixins: [],
    data () {
        return {
        }
    },
    computed: {
        // ...mapState('loader', ['loaderVisible']),
        ...mapGetters('breakpoints', ['mobile', 'desktop']),
    },
    mounted () {
        this.resize()
        window.addEventListener('resize', this.resize)
    },
    methods: {
        ...mapActions('breakpoints', ['updateBreakpoint']),
        resize () {
            this.updateBreakpoint()
        }
    },
    watch: {
    },
    head: {
        // title: {
        //     inner: headTitle,
        //     separator: ' ',
        //     complement: ''
        // },
        meta: [

            // Search Engine
            {name:"description", content: description, id:"description"},
            {name:"image", content: shareImage, id:"image"},

            // Schema.org for Google
            {itemprop:"name", content: title, id:"google_name"},
            {itemprop:"description", content: description, id:"google_description"},
            {itemprop:"image", content: shareImage, id:"google_image"},

            // Twitter
            {name:"twitter:card", content:"summary", id:"twitter_summary"},
            {name:"twitter:title", content: title, id:"twitter_title"},
            {name:"twitter:description", content: description, id:"twitter_description"},
            {name:"twitter:image:src", content: shareImage, id:"twitter_image"},

            // Open Graph general (Facebook, Pinterest & Google+)
            {name:"og:title", content: title, id:"og_title"},
            {name:"og:description", content: description, id:"og_description"},
            {name:"og:image", content: shareImage, id:"og_image"},
            {name:"og:url", content: url, id:"og_url"},
            {name:"og:site_name", content: title, id:"og_site_name"},
            {name:"og:locale", content:"da_DK", id:"og_locale"},
            {name:"og:type", content:"website", id:"og_type"},
        ]
    }
}
</script>
<style lang="scss">
@import "src/scss/common/index.scss";
* {
	box-sizing: border-box;
}

html{
	@include fontsmooth();
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	@include fluid-type($min_width, $max_width, $min_font, $max_font);
}
body{
    font-family: $font-primary;
    background-color: $color-sand;
    color: $color-dark-lighter;
    margin: 0;
}

img{
    width: 100%;
    height: auto;
}
/*
* Hideable
* --------------------------------------------------
*/
.hide--mobile{
	@include down {
		display: none !important;
	}
}
.hide--desktop{
	@include up {
		display: none !important;
	}
}



/*
* SVG
* --------------------------------------------------
*/
.svg__parent{
	position: relative;
	svg{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		fill: inherit;
	}
}


/*
* Selection
* --------------------------------------------------
*/
// ::-moz-selection { background: $color-white; color: $color-black; }
// ::selection { background: $color-white; color: $color-black; }

/*
* Colors
* --------------------------------------------------
*/
// .color--black{color: $color-black;}
// .color--white{color: $color-white;}
// .color--petrol{color: $color-petrol;}
// .color--blue{color: $color-blue;}
// .color--yellow{color: $color-yellow;}
// .color--pink{color: $color-pink;}
//
// .color--dark-lighter{color: $color-dark-lighter;}
// .color--sand{color: $color-sand;}
// .color--sand-darker{color: $color-sand-darker;}
// .color--red{color: $color-red;}
// .color--curry{color: $color-curry;}

</style>
