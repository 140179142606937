import { render, staticRenderFns } from "./Max.vue?vue&type=template&id=31644b2f&scoped=true&"
import script from "./Max.vue?vue&type=script&lang=js&"
export * from "./Max.vue?vue&type=script&lang=js&"
import style0 from "./Max.vue?vue&type=style&index=0&id=31644b2f&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31644b2f",
  null
  
)

component.options.__file = "Max.vue"
export default component.exports