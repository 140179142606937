<script>
export default {
    name: "Passage",
    render: function(createElement) {
		return createElement(
			this.type,
            {class: this.pClass},
			this.$slots.default
		)
	},
    components: {
    },
    props: {
        type: {
            type: String,
            default: 'p',
        },
        small: Boolean,
    },
    mixins: [],
    data () {
        return {
        }
    },
    computed: {
        pClass () {
            return {
                'small': this.small
            }
        }
    },
    mounted () {
    },
    methods: {
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
p{
    margin: 0;
}
.small{
    font-size: 0.9rem;
}
</style>
