<template>
    <div class="max" :style="maxStyle"><slot></slot></div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
export default {
    name: "Max",
    components: {
    },
    props: {
        width: Number,
        height: Number,
        center: Boolean,
        unit: {type: String, default: 'rem'}
    },
    mixins: [],
    data () {
        return {
        }
    },
    computed: {
        ...mapGetters('breakpoints', ['desktop']),
        maxStyle () {
            const maxWidth = this.width && `${this.width}${this.unit}`
            const maxHeight = this.height && `${this.height}${this.unit}`
            const center = this.center && {margin:'auto'}
            return this.desktop && Object.assign({},{
                maxWidth, maxHeight,
            }, center)
        }
    },
    mounted () {
    },
    methods: {
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
</style>
