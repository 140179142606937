<template>
    <div class="btn" :class="btnClass">
        <padding :ver="padding.ver" :hor="padding.hor" :bottom="padding.bottom">
            <slot></slot>
        </padding>
    </div>
</template>
<script>
import Padding from '@/components/Padding'
export default {
    name: "Btn",
    components: {
        Padding,
    },
    props: {
        secondary: Boolean,
    },
    mixins: [],
    data () {
        return {
        }
    },
    computed: {
        btnClass () {
            return {
                'btn--primary': !this.secondary,
                'btn--secondary': this.secondary
            }
        },
        padding () {
            return this.secondary ? {
                bottom: 0.2,
            } : {
                ver: 0.4,
                hor: 1.5,
                bottom: 0.6,
            }
        }
    },
    mounted () {
    },
    methods: {
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
.btn{
    display: inline-block;
    font-size: 0.9rem;
    font-weight: 700;
    cursor: pointer;
    white-space: nowrap;
}
.btn--primary{
    background-color: $color-red;
    color: $color-white;
    border-radius: 99rem;
    position: relative;
    left: -0.15rem;
    @include up {
        &:hover, &:focus{
            background-color: $color-petrol;
        }
    }
}
.btn--secondary{
    background-color: transparent;
    color: $color-red;
    border-bottom: rem(1px) solid;
    @include up {
        &:hover, &:focus{
            color: $color-petrol;
            border-bottom: rem(1px) solid transparent;
        }
    }
}
</style>
