<template>
    <div class="layout" :style="layoutStyle">
        <div class="layout__main">
            <slot></slot>
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex'
export default {
    name: "Layout",
    components: {
    },
    props: {
        align: String,
        valign: String,
        mobileAlign: String,
        mobileValign: String,
        full: Boolean,
    },
    mixins: [],
    data () {
        return {
            map: {
                start: 'flex-start',
                end: 'flex-end',
                center: 'center',
            }
        }
    },
    computed: {
        ...mapGetters('breakpoints', ['mobile']),
        a () {return this.mobile && this.notUndefiend(this.mobileAlign) ? this.mobileAlign : this.align},
        v () {return this.mobile && this.notUndefiend(this.mobileValign) ? this.mobileValign : this.valign},
        layoutStyle () {
            const f = this.full && {
                width: '100vw',
                height: '100vh',
            }
            return Object.assign({}, {
                justifyContent: this.map[this.a],
                alignItems: this.map[this.v],
            }, f)
        },
    },
    mounted () {
    },
    methods: {
        notUndefiend (value) {
            return value != undefined
        }
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
.layout{
    display: flex;
    width: 100%;
    flex-direction: row;
}
.layout__main{
    flex: 0 1 auto;
}
</style>
