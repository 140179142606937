<template>
    <div class="layer" :style="layerStyle"><slot></slot></div>
</template>
<script>
export default {
    name: "Layer",
    components: {
    },
    props: {
        layer: Number,
    },
    mixins: [],
    data () {
        return {
        }
    },
    computed: {
        layerStyle () {
            return this.layer != undefined && {
                zIndex: this.layer
            }
        }
    },
    mounted () {
    },
    methods: {
    },
    watch: {
    }
}
</script>
<style lang="scss" scoped>
@import "src/scss/common/index.scss";
.layer{
    position: relative;
}
</style>
