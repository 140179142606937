// import '@/assets/fonts/font.scss';

import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store/index'

Vue.config.productionTip = false


/*
* Head
* --------------------------------------------------
*/
import VueHead from 'vue-head'
Vue.use(VueHead)
/*
* Directives
* --------------------------------------------------
*/
// import Spy from '@/directives/Spy'
// Vue.directive('spy', Spy)

/*
* GSAP
* --------------------------------------------------
*/
import 'gsap'
// import 'gsap/ScrollToPlugin'
// import '@/lib/gsap/CustomEase'
// import '@/lib/gsap/SplitText'
// import '@/js/CustomEase'
TweenLite.defaultEase = Power4.easeOut;


/*
* Event
* --------------------------------------------------
*/
import EventBus from '@/js/EventBus'
Object.defineProperties(Vue.prototype, {$bus:{get: () => EventBus }})


/*
* VUE
* --------------------------------------------------
*/
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
